import React, { useContext, createContext, useState } from "react";

const IntlContext = createContext();

function IntlProvider(props) {
  const [lang, setLang] = useState(getLang());

  function getLang() {
    if (typeof navigator !== `undefined` && navigator.language.match("en")) {
      return "en";
    }
    return "nl";
  }

  return <IntlContext.Provider value={[lang, setLang]} {...props} />;
}

function useIntl() {
  const [context] = useContext(IntlContext);
  if (context === undefined) {
    throw new Error(`useIntl must be used within a UserProvider`);
  }
  return context;
}

function useSetIntl() {
  const context = useContext(IntlContext);
  if (context === undefined) {
    throw new Error(`useIntl must be used within a UserProvider`);
  }
  return context;
}

export { IntlProvider, useSetIntl };
export default useIntl;
