import React, { useState, createContext } from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import LOGO from "images/croppedLogo.png";
import { Link } from "gatsby";
import { useSetIntl } from "hooks";
import { HamburgerButton } from "react-hamburger-button";
import { set } from "idb-keyval";

const Nav = styled.nav`
  width: 100vw;
  height: 80px;
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  /* box-shadow: ${p => p.theme.shadows.z2}; */
  padding: 0 160px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;

  @media (max-width: ${p => p.theme.breakpoints.sm}){
    padding: 0 90px 0 14px;
  }
`;

const Logo = styled(Link)`
  height: 50px;
  margin-right: auto;
`;

const NavLink = styled(Link)`
  margin: 0 20px;
`;

const Select = styled.select`
  background: rgba(255, 255, 255, 0.1);
  color: ${p => p.theme.colors.medium};
  border: 1px solid ${p => p.theme.colors.grey};
  border-radius: 2px;
  outline: none;
  padding: 1px;
`;

const MobileNav = styled.div`
  @media (max-width: ${p => p.theme.breakpoints.sm}) {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    a {
      font-size: 50px;
      margin: 10px 0;
    }

    flex-direction: column;
    justify-content: center;
    align-items: center;

    display: ${p => (p.isOpen ? "flex" : "none")};
  }
  display: flex;
  flex-direction: row;
`;

const MenuButton = styled.div`
  position: absolute;
  right: 0;
  top: 28px;
  right: 25px;

  @media (min-width: ${p => p.theme.breakpoints.sm}) {
    display: none;
  }
`;

const Layout = ({ children }) => {
  const [locale, setLocale] = useSetIntl();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        query {
          nl: contentfulLayout(node_locale: { regex: "/nl/" }) {
            navigation
          }
          en: contentfulLayout(node_locale: { regex: "/en/" }) {
            navigation
          }
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => {
        const navText = data[locale].navigation;

        return (
          <>
            <div>
              <header>
                <Nav>
                  <Logo>
                    <img css="height: 100%" alt="logo" src={LOGO} />
                  </Logo>
                  <MobileNav isOpen={isOpen}>
                    <NavLink onClick={() => setIsOpen(false)} to="/">
                      {navText[0]}
                    </NavLink>
                    <NavLink onClick={() => setIsOpen(false)} to="/about">
                      {navText[1]}
                    </NavLink>
                    <NavLink onClick={() => setIsOpen(false)} to="/contact">
                      {navText[2]}
                    </NavLink>
                    <NavLink onClick={() => setIsOpen(false)} to="/faq">
                      {navText[3]}
                    </NavLink>
                  </MobileNav>
                  <Select onChange={a => setLocale(a.target.value)}>
                    <option value="en">EN</option>
                    <option value="nl">NL</option>
                  </Select>
                  <MenuButton>
                    <HamburgerButton
                      open={isOpen}
                      onClick={() => setIsOpen(c => !c)}
                      width={35}
                      height={25}
                      strokeWidth={3}
                      color="black"
                      animationDuration={0.2}
                    />
                  </MenuButton>
                </Nav>
              </header>

              <main css="min-height: 100vh; overflow: auto;">{children}</main>

              <footer
                css="
                color: #fff; 
                background: #292b38; 
                padding: 40px; 
                width: 100%;
                @media (max-width: ${p => p.theme.breakpoints.sm}){
                    padding: 100px;
                } 
               "
              >
                <div>
                  © {new Date().getFullYear()},{` `}
                  <a target="blank" href="https://github.com/jescowuester/">
                    Jesco Wuester
                  </a>{" "}
                  &{" "}
                  <a target="blank" href="https://github.com/zlobic">
                    David Zlobinskyy
                  </a>
                </div>
              </footer>
            </div>
          </>
        );
      }}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
