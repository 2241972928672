import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html {
  box-sizing: border-box;
  font-family: "Roboto";
  font-weight: 400;
  color: rgb(78, 93, 120);
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



h1{
  font-size: 100px;
  font-weight: 500;
}
h2 {
  font-size: 24px;
  font-weight: 500;
}
h3 {
  font-size: 46px;
}
p {
  font-size: 18px;
}

hr {
  border: none;
  border-bottom: 2px solid #5a525e;
}

@media (max-width: ${p => p.theme.breakpoints.sm}){
  h1{
  font-size: 40px;
  font-weight: 500;
}
h2 {
  font-size: 20px;
  font-weight: 500;
}
h3 {
  font-size: 36px;
}
p {
  font-size: 14px;
}

hr {
  border: none;
  border-bottom: 2px solid #5a525e;
}
}

`;

export default GlobalStyle;
