export default {
  name: "default",
  colors: {
    brand: "#5aad76",
    brandLight: "#a4f9c1",
    brandDark: "#43965f",
    yellowDark: "#e8b700",
    yellow: "#F6C300",
    yellowLight: "#ffdb54",
    red: "#f44248",
    beige: "rgba(246, 195, 0, 0.1)",
    white: "#fff",
    whiteFake: "#FAFAFC",
    greyLight: "#EDEDEF",
    greyLighter: "#f2f2f5",
    medium: "#4e5d78",
    grey: "#B5B2BB",
    black: "#1F1424",
    overlay: "rgba(0, 0, 0, 0.7)"
  },
  shadows: {
    z1:
      "0px 0px 1px 0px rgba(10, 22, 70, 0.06), 0px 1px 1px 0px rgba(10, 22, 70, 0.1)",
    z2:
      "0px 0px 1px 0px rgba(10, 22, 70, 0.06), 0px 3px 3px -1px rgba(10, 22, 70, 0.1)",
    z3:
      "0px 0px 1px 0px rgba(10, 22, 70, 0.06), 0px 6px 6px -1px rgba(10, 22, 70, 0.1)",
    z4:
      "0px 0px 1px 0px rgba(10, 22, 70, 0.06), 0px 16px 16px -1px rgba(10, 22, 70, 0.1)",
    z5:
      "0px 0px 1px 0px rgba(10, 22, 70, 0.06), 0px 32px 40px -2px rgba(10, 22, 70, 0.12)"
  },
  layout: {
    sideMargin: "15%"
    // sideMargin: "242px"
  },
  fontSizes: ["14px", "18px", "23px", "26px", "36px", "72px"],
  breakpoints: {
    sm: "762px",
    md: "950px",
    lg: "1275px",
    xl: "1300px"
  }
};
