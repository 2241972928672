import React from "react";
import { ThemeProvider } from "styled-components";
import { IntlProvider } from "hooks";
import theme from "./theme";
import GlobalStyle from "./GlobalStyle";

export default ({ element }) => (
  <ThemeProvider theme={theme}>
    <IntlProvider>
      <>
        <GlobalStyle />
        {element}
      </>
    </IntlProvider>
  </ThemeProvider>
);
